﻿import $$ from "../utils/double-dollar";

class DisableOnSubmit {
  constructor(private element: Element) {
    element.addEventListener("submit", (e) => {
      const form = e.target as HTMLFormElement;
      const buttons = form.querySelectorAll('button[type="submit"]');
      buttons.forEach((button) => {
        button.setAttribute("disabled", "disabled");
      });

      const formGroup = form.dataset.formGroup;
      if (!formGroup) {
        return;
      }
      const formsInGroup = document.querySelectorAll(`[data-form-group="${formGroup}"]`);
      formsInGroup.forEach((formInGroup) => {
        if (formInGroup === form) {
          return;
        }
        const buttonsInGroup = formInGroup.querySelectorAll('button[type="submit"]');
        buttonsInGroup.forEach((button) => {
          button.setAttribute("disabled", "disabled");
        });
      });
    });
  }

  public static init() {
    $$("form.js-disable-on-submit", (el) => new DisableOnSubmit(el));
  }
}

DisableOnSubmit.init();
